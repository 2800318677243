import { SendOutlined } from "@ant-design/icons";
import { Button, DatePicker, Form, Input, message, Result, Select } from "antd";
import "antd/dist/antd.css";
import enLocale from "antd/es/date-picker/locale/en_US";
import fiLocale from "antd/es/date-picker/locale/fi_FI";
import axios from "axios";
import moment from "moment";
import qs from "qs";
import React, { FunctionComponent, useState } from "react";
import i18n, { Lang } from "../i18n";
import Prices, { Price, PricesProps } from "./Prices";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
    lg: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
    lg: { span: 10 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
    lg: {
      span: 10,
      offset: 4,
    },
  },
};

moment.locale("fi-fi", {
  week: {
    dow: 1,
  },
});

const CabinForm: FunctionComponent<{
  lang: Lang;
  cabinSlug: string;
  cabinName: string;
  prices: Price[];
}> = ({ lang, cabinSlug, cabinName, prices }) => {
  const locale = lang === "fi" ? fiLocale : enLocale;

  const [form] = Form.useForm();
  const [sent, setSent] = useState(false);
  const [yearWeek, setYearWeek] = useState<
    Omit<PricesProps, "prices" | "lang"> | undefined
  >(undefined);

  const msgs = i18n[lang].reservationForm;

  const onDateChanged = ($: any, [startDate, endDate]: [any, any]) => {
    const start = moment(startDate);
    setYearWeek({ year: start.year(), weekNumber: start.isoWeek() });
  };

  const handleSubmit = async (values: any) => {
    values.cabinSlug = cabinSlug;
    values.cabinName = cabinName;
    values.dateStart = values.dates[0].format("YYYY-MM-DD");
    values.dateEnd = values.dates[1].format("YYYY-MM-DD");
    values["form-name"] = "varauslomake";
    delete values.dates;

    console.log("Sending reservation with values", values);

    try {
      await axios.post(window.location.href, qs.stringify(values));
      setSent(true);
      message.success(msgs.reservationSent.message);
    } catch (e) {
      message.error(msgs.reservationFailed.message);
    }
  };

  const resetForm = () => {
    form.resetFields();
    setSent(false);
  };

  return (
    <>
      <div>
        <h2>{msgs.general.message}</h2>

        {sent ? (
          <Result
            status="success"
            title={msgs.reservationSent.message}
            subTitle={msgs.reservationSent.description}
            extra={[
              <Button key="reserve-again" type="primary" onClick={resetForm}>
                {msgs.reservationSent.reserveAgain}
              </Button>,
            ]}
          />
        ) : (
          <Form
            name="varauslomake"
            form={form}
            onFinish={handleSubmit}
            data-netlify="true"
            {...formItemLayout}
          >
            <input type="hidden" name="form-name" value="varauslomake" />

            <input type="hidden" name="cabinName" value={cabinName} />

            <input
              type="hidden"
              name="dateStart"
              value="dateStartPlaceholder"
            />

            <input type="hidden" name="dateEnd" value="dateEndPlaceholder" />

            <Form.Item
              label={msgs.cabin.title}
              name="cabin"
              initialValue={cabinName}
            >
              <Input disabled value={cabinName} />
            </Form.Item>

            <Form.Item
              name="dates"
              label={msgs.dates.title}
              rules={[{ required: true, message: msgs.dates.errors.required }]}
            >
              <DatePicker.RangePicker
                locale={locale}
                placeholder={[msgs.startDate.title, msgs.endDate.title]}
                onChange={onDateChanged}
              />
            </Form.Item>

            {prices && (
              <Form.Item name="price" label={msgs.price.title}>
                <Prices
                  lang={lang}
                  prices={prices}
                  year={yearWeek?.year}
                  weekNumber={yearWeek?.weekNumber}
                />
              </Form.Item>
            )}

            <Form.Item
              name="numberOfPersons"
              label={msgs.numberOfPersons.title}
              rules={[
                {
                  required: true,
                  message: msgs.numberOfPersons.errors.required,
                },
              ]}
              initialValue={"1"}
            >
              <Select style={{ width: "50%" }}>
                <Select.Option value="1">1</Select.Option>
                <Select.Option value="2">2</Select.Option>
                <Select.Option value="3">3</Select.Option>
                <Select.Option value="4">4</Select.Option>
                <Select.Option value="5">5</Select.Option>
                <Select.Option value="6">6</Select.Option>
                <Select.Option value="7">7</Select.Option>
                <Select.Option value="8">8</Select.Option>
                <Select.Option value="9">9</Select.Option>
                <Select.Option value="10">10</Select.Option>
                <Select.Option value="11">11</Select.Option>
                <Select.Option value="12">12</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="name"
              label={msgs.name.title}
              rules={[{ required: true, message: msgs.name.errors.required }]}
            >
              <Input placeholder={msgs.name.placeholder} name="name" />
            </Form.Item>

            <Form.Item
              name="email"
              label={msgs.email.title}
              rules={[
                { required: true, message: msgs.email.errors.required },
                {
                  type: "email",
                  message: msgs.email.errors.validEmail,
                },
              ]}
            >
              <Input placeholder={msgs.email.placeholder} name="email" />
            </Form.Item>

            <Form.Item
              name="phone"
              label={msgs.phone.title}
              rules={[{ required: true, message: msgs.phone.errors.required }]}
            >
              <Input placeholder={msgs.phone.placeholder} name="phone" />
            </Form.Item>

            <Form.Item name="extra" label={msgs.extra.title}>
              <Input.TextArea
                rows={5}
                placeholder={msgs.extra.placeholder}
                name="extra"
              />
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
              <Button type="primary" icon={<SendOutlined />} htmlType="submit">
                {msgs.send}
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    </>
  );
};

export default CabinForm;
