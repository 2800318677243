import { graphql } from "gatsby";
import Image from "gatsby-image";
import { RichText } from "prismic-reactjs";
import React, { useState } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import CabinForm from "../components/CabinForm";
import CabinList from "../components/CabinList";
import Layout from "../components/Layout";
import Map from "../components/Map";
import { Cabin } from "../types";
import htmlSerializer from "../utils/html-serializer";

export const query = graphql`
  query CabinQuery($uid: String!, $lang: String!) {
    prismic {
      cabin(uid: $uid, lang: $lang) {
        _meta {
          lang
          uid
        }
        name
        description
        features
        location
        pictures {
          picture
          pictureSharp {
            id
            picture: childImageSharp {
              main: fluid {
                src
                srcSet
                aspectRatio
                sizes
              }
              thumbnail: fluid(
                maxWidth: 400
                maxHeight: 400
                cropFocus: CENTER
              ) {
                src
                aspectRatio
                srcSet
                sizes
              }
            }
          }
        }
        prices {
          weekendprice
          weeknumber
          weekprice
          year
        }
      }
      allCabins(lang: $lang) {
        edges {
          node {
            _meta {
              id
              lang
              uid
            }
            name
            shortdescritpion
            startingpriceperweek
            squaremeters
            personsmin
            personsmax
            pictures {
              picture
              pictureSharp {
                id
                picture: childImageSharp {
                  main: fluid(
                    maxWidth: 400
                    maxHeight: 270
                    cropFocus: CENTER
                  ) {
                    src
                    aspectRatio
                    srcSet
                    sizes
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const CabinPage = (props: any) => {
  const [modalState, setModalState] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  const openModal = (index: number) => {
    setImageIndex(index);
    setModalState(true);
  };

  const cabin = props.data.prismic.cabin;

  const cabins: Cabin[] = props.data.prismic.allCabins.edges
    .map((edge: any) => edge.node)
    .map((node: any) => ({
      uid: node._meta.uid,
      name: node.name[0].text,
      shortDescription: node.shortdescritpion
        ? node.shortdescritpion[0].text
        : "",
      picture:
        node.pictures && node.pictures[0].pictureSharp
          ? node.pictures[0].pictureSharp.picture.main
          : null,
      // TODO
      price: node.startingpriceperweek,
      area: node.squaremeters,
      minPerson: node.personsmin,
      maxPerson: node.personsmax,
    }))
    .filter((cabin: Cabin) => cabin.picture);

  if (!cabin) return null;

  const allPictures = cabin.pictures.filter(
    (picture: any) =>
      picture?.pictureSharp?.picture?.thumbnail &&
      picture?.pictureSharp?.picture?.main
  );

  const firstPicture = allPictures.slice(0, 1).pop();
  const otherPictures = allPictures.slice(
    1,
    Math.min(cabin.pictures.length, 8)
  );

  return (
    <Layout title={RichText.asText(cabin.name)} lang={cabin._meta.lang}>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-8">
        <div className="col-start-1 row-start-2 lg:row-start-1 lg:row-span-2">
          <div
            className="cursor-pointer mb-4"
            onClick={() => openModal(0)}
            onKeyPress={() => openModal(0)}
            role="link"
          >
            {firstPicture && (
              <Image fluid={firstPicture.pictureSharp.picture.main} />
            )}
          </div>

          <div className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-4 gap-4">
            {otherPictures.map((picture: any, index: number) => (
              <div
                key={`thumbnail-${index}`}
                className="cursor-pointer"
                onClick={() => openModal(index + 1)}
                onKeyPress={() => openModal(index + 1)}
                role="link"
              >
                <Image fluid={picture.pictureSharp.picture.thumbnail} />
              </div>
            ))}
            {allPictures.length >= 9 && (
              <div
                key="more-images"
                className="relative cursor-pointer"
                onClick={() => openModal(8)}
                onKeyPress={() => openModal(8)}
                role="link"
              >
                <Image fluid={allPictures[8].pictureSharp.picture.thumbnail} />
                <div className="absolute w-full h-full top-0 left-0 opacity-50 bg-black text-white text-center pt-12">
                  Lisää kuvia…
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="col-start-1 lg:col-start-2 row-start-1 text-center lg:text-left">
          <RichText render={cabin.name} />
        </div>

        <div className="col-start-1 lg:col-start-2 row-start-3 lg:row-start-2">
          <RichText
            render={cabin.description}
            htmlSerializer={htmlSerializer}
          />
          <ul>
            <RichText render={cabin.features} htmlSerializer={htmlSerializer} />
          </ul>
          {cabin.location && (
            <Map
              latitude={cabin.location.latitude}
              longitude={cabin.location.longitude}
              placeName={cabin.name[0].text}
            />
          )}
        </div>
      </div>
      <div>
        <CabinForm
          lang={cabin._meta.lang}
          cabinSlug={cabin._meta.uid}
          cabinName={RichText.asText(cabin.name)}
          prices={cabin.prices}
        />
      </div>
      <div>
        <h2>Muut mökit</h2>
        <CabinList
          cabins={cabins}
          lang={props.pageContext.lang}
          excludeUid={cabin._meta.uid}
        />
      </div>

      <ModalGateway>
        {modalState ? (
          <Modal
            onClose={() => setModalState(false)}
            styles={{
              blanket: (base) => ({
                ...base,
                zIndex: 100,
              }),
              positioner: (base) => ({
                ...base,
                zIndex: 100,
              }),
            }}
          >
            <Carousel
              views={allPictures.map(
                (picture: any) => picture.pictureSharp.picture.main
              )}
              currentIndex={imageIndex}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </Layout>
  );
};

export default CabinPage;
