import React, { FunctionComponent } from "react";
import i18n, { Lang } from "../i18n";

export type Price = {
  year: number;
  weeknumber: number;
  weekendprice: number;
  weekprice: number;
};

export type PricesProps = {
  lang: Lang;
  prices: Price[];
  year?: number;
  weekNumber?: number;
};

const Prices: FunctionComponent<PricesProps> = ({
  lang,
  prices,
  year,
  weekNumber,
}) => {
  const msgs = i18n[lang].reservationForm;

  if (!year || !weekNumber) {
    return <span>{msgs.price.placeholder}</span>;
  }

  const price = prices.find(
    (price) => price.year === year && price.weeknumber === weekNumber
  );

  if (!price || !price.weekprice) {
    return <span>{msgs.price.errors.missingPrice}</span>;
  }

  return (
    <>
      <table>
        <tbody>
          <tr>
            <th className="text-right pr-2">{msgs.price.weekendPrice}:</th>
            <td>
              {price.weekendprice
                ? `${price.weekendprice} €`
                : msgs.price.errors.onlyWeeks}
            </td>
          </tr>
          <tr>
            <th className="text-right pr-2">{msgs.price.weekPrice}:</th>
            <td>{price.weekprice} €</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default Prices;
