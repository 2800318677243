import React from "react";

const API_KEY = "AIzaSyBzkEOVtNHZ--O8L8NtO1DJqztNi1xEXlY";

type MapProps = {
  latitude: number;
  longitude: number;
  placeName: string;
};

export default function Map(props: MapProps) {
  const { latitude, longitude, placeName } = props;

  const coordinate = `${latitude} ${longitude}`;

  console.log(latitude, longitude, placeName);

  const query = `Toijalan+tila,Tampere`;

  return (
    <iframe
      width="100%"
      height="300"
      frameBorder="0"
      src={`https://www.google.com/maps/embed/v1/place?key=${API_KEY}&q=${query}&center=${coordinate}&language=fi&zoom=15`}
      allowFullScreen={true}
    ></iframe>
  );
}
